import _lua from "./lua.js";
import _markupTemplating from "./markup-templating.js";
var exports = {};
var refractorLua = _lua;
var refractorMarkupTemplating = _markupTemplating;
exports = etlua;
etlua.displayName = "etlua";
etlua.aliases = [];

function etlua(Prism) {
  Prism.register(refractorLua);
  Prism.register(refractorMarkupTemplating);

  (function (Prism) {
    Prism.languages.etlua = {
      delimiter: {
        pattern: /^<%[-=]?|-?%>$/,
        alias: "punctuation"
      },
      "language-lua": {
        pattern: /[\s\S]+/,
        inside: Prism.languages.lua
      }
    };
    Prism.hooks.add("before-tokenize", function (env) {
      var pattern = /<%[\s\S]+?%>/g;
      Prism.languages["markup-templating"].buildPlaceholders(env, "etlua", pattern);
    });
    Prism.hooks.add("after-tokenize", function (env) {
      Prism.languages["markup-templating"].tokenizePlaceholders(env, "etlua");
    });
  })(Prism);
}

export default exports;